.admin-options{
    border: 1px solid white;
    height: 750px;
    width: 90%;
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    /* padding: %; */
}

.options-rows{
    position: relative;
    margin: auto;
    /* padding: 5px; */
}

.option{
    border: 1px solid white;
    height: 275px;
    width: 400px;
    margin: 30px;
    
}

.option:hover{
    background-color: white;
    color: black;
}

.selectable-label{
    position: relative;
   
    
    top: 125px;
    /* font-size: 2em; */
    font-weight: 100;
}

.admin-welcome{
    position: relative;
    float: left;
    text-align: left;
    left: 5%;
    
}

.admin-greeting{
    font-size: 2em;
}

.left-align{
    text-align: left;
    padding-left: 20%;
}

.left-item{
    display: flex;
}

.right-inputs{
    position: relative;
    margin-left: 7% ;
}

.edit-proj-total{
    background-color: #333;
    color: white;
    border: none;
    margin-left: 1%;
    /* font-size: .3em; */
}

#total-display{
    position: relative;
    display: flex;
}

#total-display button{
    margin-left: 75px;
    font-size: .8em;
}

.totals-edit-form{
    padding: 3%;
    display: flex;
    border: 1px solid #333;
    height: 50px;
    width: 80%;
}

.totals-edit-form img{
    margin-left: 5%;
}

@media(max-width: 400px){
    .admin-options{
        border: none;
        height: 100px;
        width: 80vw;
        position: absolute;
        margin: auto;
        display: block;
        align-items: center;
        /* padding: %; */
    }  
    
    .admin-welcome{
        position: relative;
        /* float: left; */
        /* text-align: left; */
        left: 0%;
        font-weight: 100;
        
    }
    
    .option{
        border: 1px solid white;
        height: 75px;
        width: 90vw;
        margin-left: 15px;
        /* left: -10px ; */
        /* margin-left: auto;
        margin: 30px; */
        
    }

    .selectable-label{
        position: relative;
       
        
        top: 20px;
        /* font-size: 2em; */
        font-weight: 100;
    }

    .options-rows{
        position: relative;
        margin: auto;
        top: 140px;
        /* padding: 5px; */
    }
}