.project-data-display{
    border-top: 2px solid #333;
    /* align-content: center;
    height: 800px;
    width: 90%;
    margin: auto; */
}

.project-modules{
    display: flex;
}

.edit-proj-title{
    background-color: #333;
    color: white;
    border: none;
    margin-left: 1%;
    /* font-size: .3em; */
}

.proj-mod{
    /* position: relative; */
    border: 1px solid #333;
    width: 500px;
    margin-left: 45px;
    margin-bottom: 15px;
    height: 300px;  
    overflow: scroll;
    border-radius:  10% 10%;
    /* color: #333; */

}

/* .proj-mod-name{
    color: #333;
} */


.proj-mod:hover{
    border: 1px solid white;
    color: white;
    
}

.proj-info{
    font-size: .7em;
    font-weight: 100;
}

#project-title{
    font-size: 2em;
    font-weight: 400;
    
}

#project-description{
    font-size: 1.5em;
    font-weight: 200;

}

.project-image-preview{
    height: 150px;
    width: 130px;
    /* padding-right: 1%; */
}

.request-image-box{
    margin-left: 0px;
    padding-right: 10%;
}

.project-image-full{
    height: 200px;
    width: 200px;
}

.id-title-description{
    text-align: left;
}

.id-image{
    height: 200px;
    width: 250px;
}

.image-preview{
    height: 50px;
    width: 50px;
}

.date-form{
    display: flex;
}

.date{
    display: flex;
    font-size: 1.3em;
    padding: 1%;
    
}

.time{
    display: flex;
    font-size: 1.3em;
    padding: 1%;
}

select{
    border-radius: 15%;
    margin-left: 10px;
}

.date-time select{
    height: 57px;
    font-weight: 900;
    font-size: .7em;
}

.daypart-duration{
    width: 75px;
    margin-left: 10px;
}

.add-new-btn{
    background-color: #333;
    border: none;
    color: white;
    font-weight: 200;
    font-size: 1em;
    padding: 20px;
    margin-top: 10px;
    align-items: center;
}

.add-new-btn:hover{
    border: 2px solid rgba(235, 61, 61, 0.664)
}

.custom-file-upload:hover{
    border: 2px solid rgba(235, 61, 61, 0.664)
}

.custom-file-btn:hover{
    border: 2px solid rgba(235, 61, 61, 0.664)
}

.proj-imag-upload:hover{
    border: 2px solid rgba(235, 61, 61, 0.664)
}

/* input[type="file"] {
    display: none;
} */

.proj-user-mod{
    display: inline;
    overflow: scroll;
}

.upload-message{
    position: relative;
    top: 0px;
}

.proof-of-age{
    height: 50%;
}

.custom-file-upload{
    /* position: relative;
    background-color: #333;
    height: 20px;
    padding: 10px;
    font-weight: 300; */
    /* margin-top: 10px;    */
    background-color: #333;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 1em;
    padding: 20px;
    margin-top: 10px;
    align-items: center;
}

.custom-file-btn{
    position: relative;
    top: -30px;

    background-color: #333;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 1em;
    padding: 20px;
    margin-top: 10px;
    align-items: center;
}

.proj-imag-upload{
    position: relative;
    top: 30px;

    background-color: #333;
    border: none;
    color: white;
    font-weight: 400;
    font-size: 1em;
    padding: 20px;
    margin-top: 10px;
    align-items: center;
}
/* .date-form{
    margin-left:  100px;
} */
#final-images{
    position: relative;
    top: 50px;
}
#save-button{
    border: 1px solid grey;
    width: 100px;
    text-align: center;
    /* padding-top: 0%; */
    margin-left: 2%;
    /* padding: auto; */
}
#save-button:hover{
    background-color:rgba(17, 83, 34, 0.664);
}



@media(max-width: 400px){
    .project-modules{
        display: block;
    }

    .project-data-display{
        border-top: none;
    }

    .proj-mod{
        /* position: relative; */
        border: 1px solid rgba(235, 235, 235, .4);
        width: 90%;
        margin-left: 16px;
        margin-bottom: 15px;
        height: 300px;  
        overflow: scroll;
    
    }

    .date-form{
        display: block;
        margin-left: -15px;
    }
}
