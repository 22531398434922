/* li{ */
    /* position: relative; */
    /* top: 30px; */
    /* font-weight: 100; */
/* } */

:root {
  --pcol: black;
  /* --scol: green; */

  --tspeed: 0.6s;
  --base-scale: 1.1;
  --subMenu-width: 12.25rem;
  --fgrad: linear-gradient(
    139deg,
    /* rgba(51, 51, 51, 1) 16%,
    rgb(136, 44, 44) 52%,
    rgba(51, 51, 51, 1) 88% */
  );
  --sgrad: linear-gradient(
    139deg,
    /* rgba(51, 51, 51, 1) 16%,
    rgb(129, 28, 129) 76%,
    rgba(51, 51, 51, 1) 100% */
  );
  /* text-decoration: none; */
 
  --rm-font-size: 0.875rem;
  --sm-font-size: 0.65rem;
}

nav{
  width: 98vw;
  height: 80px;
  padding-left: 1%;
  margin-top: 2.5%;
  margin-bottom: 20px;
  background-color: black;
  display: flex;
  font-size: 1em;
  color: white;
  justify-content: space-between;
  
}

.x img{
  margin-left: 20px; 
  /* margin-left: 70;  */
}

.menu{
  margin-right: 20px;
  margin-top: 45px;
  
}

.sub-item{
  list-style-type: none;
  position: relative;
  left: -40px;
  /* text-align: left; */
  /* margin: auto; */
  z-index: 2;
  font-size: 1.45em;
  font-weight: 300;
  color: black;

}

.sub-items{
  background-color: rgba(160, 160, 160, .8);
  font-size: .6em;
  border-radius: 10%;
  /* height: 100%; */
  height: 95% ;
  width: 60%;
  text-align: left;
  position: relative;
  top: 10px;
  z-index: 2;
  /* left: -100px; */
}

#artists{
  font-size: 1.45em;
  font-weight: 300;
}





@media(max-width: 380px){
  nav{
    /* width: 50vw; */
    height: 80px;
    width: 90vw;
    padding-left: 1%;
    /* margin-top: 3%; */
    margin-bottom: 10px;
    background-color: black;
    display: flex;
    font-size: 1em;
    color: white;
    justify-content: space-between;
  }

  .x img{
    margin-left: 5px;
    height: 85px;
  }

  .sub-item{
    list-style-type: none;
    position: relative;
    /* left: -40px; */
    /* text-align: left; */
    /* margin: auto; */
    z-index: 2;
    font-size: 1.45em;
    font-weight: 300;
  
  }
  
  .sub-items{
    /* background-color: rgba(160, 160, 160, 0.445); */
    font-size: .6em;
    border-radius: 10%;
    /* height: 100%; */
    height: 85% ;
    width: 60%;
    text-align: left;
    position: relative;
    top: 10px;
    z-index: 2;
    /* left: -100px; */
  }

  .sign-out{
    margin-bottom: 5%;
  }
}
