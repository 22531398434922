/* WEB */
body{
  background-color: black;
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
  color: white;
}

.link-boxes{
  position: relative;
  width: 65%;
  height: 100%;
  margin: auto;
  /* border: 1px solid black; */
  display: flex;
}

.box{
  border: 1px solid rgb(218,55,49);
  position: relative;
  width: 40%;
  height: auto;
  margin: auto;
  padding: 3%;
}

.box a{
  position: relative;
  top: 40%;
}


#headline{
  font-size: 450%;
  font-weight: 400;
}

.headline-2{
  font-size: 450%;
  font-weight: 200;
  text-transform: uppercase;
  /* font-size: 2em; */
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 6s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}


#sub-head{
  font-size: 350%;
  font-weight: 100;
}

a{
  text-decoration: none;
  color: white;
  font-family:'Arial Narrow Bold', sans-serif;
  font-size: 1.5em;
  font-weight: 100;
}

.logo{
  width: 500px;
  height: 350px;
  
}


/* PHONE */
@media (max-width: 375px){
  #headline-2{
    text-align: center;
    font-size: 20%;
    margin: auto;
    position: relative;
    top: 0px;
    text-transform: uppercase;
    /* font-size: 1.5em; */
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }

  .logo{
    position: relative;
    margin: auto;
    width: 350px;
    height: 250px;
    top: 100px;
  }
  .link-boxes{
    position: relative;
    left: -65px;
    width: 500px;
    height: 300px;
    margin: auto;
    display: block;
    top: 50px;
    text-align: center;
  }

  #right{
    margin-top: 15px;
  }
}

/* PHONE LANDSCAPE */
@media (max-width: 815px){
  #headline-2{
    text-align: center;
    font-size: 100%;
    margin: auto;
    position: relative;
    top: 0px;
    text-transform: uppercase;
    /* font-size: 1.5em; */
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }
}

