.proj-attrs{
    border: 1px solid white;
    width: 450px;
    padding: 15px;
    text-align: left;
}

.all-projs{
    display: flex;
}

#incomplete-projs{
    margin-left: 10%;
}

#complete-projs{
    position: relative;
    margin-left: 20%;
}

#request-images{
    position: relative;
    display: flex;
    margin-left: 19%;
}

@media(max-width: 400px){
    .all-projs{
        display: block;
    }

    #incomplete-projs{
        margin-left: 5%;
    }

    #complete-projs{
        position: relative;
        margin-left: 5%;
    }

    .proj-attrs{
        border: 1px solid white;
        width: 300px;
        padding: 15px;
        text-align: left;
    }
    #request-images{
        position: relative;
        display: flex;
        margin-left: 3%;
    }
}