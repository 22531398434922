#notes{
    /* text-align: left; */
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.edit-note-button{
    float: right;
    background-color: black;
    color: rgba(218,55,49,1);
    font-weight: 400;
}
