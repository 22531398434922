.user-attrs{
    border: 1px solid white;
    position: relative;
    width: 250px;
    padding: 10px;
    text-align: left;
    margin-bottom: 1%;
}
/* 
.user-display{
    border: 1px solid white;
} */

table{
    border-collapse:collapse;
    border:1px solid white;
    position: relative;
    margin: auto;
}

table td{
    border:1px solid white;
    padding: 5px;
}

table th{
    font-size: 1.5em;
    padding: 5px;
}

@media(max-width: 400px){
    h1{
        font-size: .8em;
    }
     table{
        font-size: .5em;
    }
   
}