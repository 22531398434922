.home-logo{
    width: 75vw;
    height: 100vh;
}

.home-image-window{
    /* margin-left: 5%; */
    position: relative;
    overflow: scroll;
    height: 640px;
    width: 96vw;
    margin-left: 2%;
    border: 1px solid rgb(105, 105, 105);
    /* border-radius: ; */
}

.home-image-display{
    display: flex;
    width: 95vw;
    
    /* margin: auto; */
    /* padding: 0px 100px 0px; */
}

.home-image-display img{
    height: 540px;
    width: 380px;
}

.slide{
    /* height: 100vh; */
    width: 100%;
    /* background-image: url('${content}'); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
}

.social-icons{
    display: flex;
    position: absolute;
    right: 200px;
    margin-top: 10px;
}

.icon{
    
    height: 30px;
    width: 30px;
    padding-left: 20%;
    
    /* background-color: white; */
}

@media(max-width: 400px){
    .home-image-window{
        margin-left: 0%;
        position: relative;
        overflow: scroll;
        /* height: 643px; */
        width: 99vw;
        /* margin-left: auto; */
        /* border: 5px solid rgb(105, 105, 105); */
        /* border-radius: ; */
    }
    

    .home-image-display img{
        height: 540px;
        width: 375px;
    }

    .social-icons{
        display: flex;
        position: absolute;
        left: 10px;
        margin-top: 20px;
    }

    .icon{
    
        height: 23px;
        width: 23px;
        padding-left: 12%;
        
        /* background-color: white; */
    }
    
}