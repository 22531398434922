.tr-req-form{
    position: relative;
    /* left: 5%; */
    width: auto;
    /* float: left; */
    /* margin-left: 5%; */
    margin: auto;
}

/* input[type="file"]{
    display:block;
} */

.tr-req-form h1{
    font-size: 3em;
}

.tr-req-form label{
    position: absolute;
    font-size: 1.7em;
    font-family:Arial, Helvetica, sans-serif;
    letter-spacing: .2ch;
    left:  400px;
}

.tr-form-options{
    position: relative;
    /* display: flex; */
}

.tr-admin-options{
    border: 1px solid white;
    position: relative;
    margin: auto;
    width: 30vw;
    background-color: #333;
    height: 100px;
    /* font-weight: 500; */
    
}

.form-submit-button {

    /* background: rgb(218,55,49);
    color: white;
    border-style: outset;
    border-color:black;
    height: 80px;
    width: 50%;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 3em;
    letter-spacing: .2ch;
    font-weight: 100;   */


    background: rgb(218,55,49);
    /* background: #333; */
    /* margin-top: 10px; */
    margin: auto;
    color: white;
    border-style: outset;
    border-color:black;
    height: 100%;
    width: 40%;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 2em;
    letter-spacing: .2ch;
    font-weight: 500;  
}

.form-submit-button:hover{
    /* color: #333; */
    border: 1px solid #fff;
}

.success-message{
    background-color: rgba(9, 196, 65, 0.87);
    position: relative;
    width: 60%;
    height: 100px;
    font-size: 1.7em;
    padding: 5%;
    margin: auto;
    top: 100px;
    font-weight: bold;
    border-radius: 50px 5px / 80px ;
}

@media(max-width: 400px){
    .tr-req-form h1{
        font-size: 1.5em;
    }
    .tr-req-form label{
        position: fixed;
        font-size: 1em;
        font-weight: bold;
        font-family:Arial, Helvetica, sans-serif;
        letter-spacing: .2ch;
        left:  1px;
        margin-top: -20px;
    }

    .tr-req-form{
        position: relative;
        /* left: 5%; */
        width: 50vw;
        /* float: left; */
        /* margin-left: 5%; */
        margin: auto;
    }

    .form-submit-button {
        background: rgb(218,55,49);
        color: white;
        border-style: outset;
        /* border-color:black; */
        height: 80px;
        width: 100%;
        font-family:Arial, Helvetica, sans-serif;
        font-size: 2em;
        letter-spacing: .2ch;
        font-weight: 500;   
    }

    .tr-admin-options{
        border: 1px solid white;
        position: relative;
        display: block;
        margin: auto;
        width: 90vw;
        background-color: #333;
        height: 100px;
        /* font-weight: 500; */
        
    }

    input.input-field, input.password, textarea.description-field{
        width: 300px;
        /* margin-left: 0px; */
        font-size: 1.2em;

    }

    textarea.description-field{
        margin-left: 5px;
    }

    label{
        margin-left: 20px;
    }

    .success-message{
        font-size: 1.5em;
    }

    

    

   
}

