.full-screen{
    border: 10px solid grey;
    z-index: 5;
    position: absolute;
    left: 5%;
    top: 50px;
    height: 700px;
    width: 1500px;
    background-color: rgba(255, 255, 255, .95);
    color: black;
    text-align: left;
    font-weight: 200;
}

.full-screen h1{
    font-weight: 200;
}

.fs-top{
    display: flex;
    height: 180px;
}

.fs-top-title{
    border: 1px solid grey;
    width: 34%;
    padding-left: 1%;
    /* font-weight: 100; */
    font-size: 1.2em;
    overflow: scroll;
    /* margin-left: .5%; */
}

.fs-top-options{
    border: 1px solid grey;
    width: 70%;
    padding-left: 1%;
    margin-left: 5%;
    /* font-weight: 100; */
    font-size: 1.2em;
    overflow: scroll;
    /* margin-left: .5%; */
}

.request-options{
    border: 1px solid grey;
    margin-left: 5%;
    padding: 3%;
}

.request-options:hover{
    background-color: grey;
    color: white;
}


.fs-body-location-image{
    width: 300px;
    margin-left: 5%;
}

.fs-top-appts{
    /* align-items: flex-end; */
    border: 1px solid grey;
    position: relative;
    width: 70%;
    padding-left: 1%;
    overflow: scroll;

    /* margin-left: auto; */
    /* margin-right: 2%; */
}

.project-image{
    border: 1px solid grey;
    height: 300px;
    width: 25%;
    margin-left: 1%;
    /* font-weight: 500; */
    font-size: 1.5em;

}

.fs-bottom{
    display: flex;
}

.schedule-options{
    margin-left: 16.3%;
    width: 100%;
}

.fs-bottom-mock-loc-img{
    display: flex;
    /* padding-left: 1%; */
}

.fs-bottom h1{
   align-content: center;
}

.progress-image{
    height:250px;
    width: 45%;
    margin: 2px;
}

.mock-loc-image{
    height:250px;
    width: 90%;
    margin-left: 5%;
}

.exit-x{
    position: absolute;
    right: 10px;
    font-size: 2em;
    font-weight: 500;
    color: red;
}

.directionals{
    display: flex;
    padding-top: 2%;;
}

#prev {
    border: 1px solid black;
    margin-left: 2%;
    padding: 2%;

}

#next{
    border: 1px solid black;
    position: absolute;
    right: 0px;
    margin-right: 2%;
    padding: 2%;
}

.window-open{
    background-color: green;
    height: 150px;
    width: 400px;
    margin-top: auto;
}

.window-closed{
    background-color: red;
    height: 150px;
    width: 400px;
    margin-top: auto;
}

.open-close-option{
    border: 1px solid grey;
    height: 150px;
    width: 400px;
    margin: auto;
    /* padding: 1%; */
    font-size: 2em;
    font-weight: 500;
    text-align: center;
}

.admin-option{
    border: 2px solid grey;
    margin-left: 5%;
    height: 275px;
    width: 600px;
}

.fs-top-level{
    position: relative;
    display: flex;
    margin-bottom: 1%;
}
.fs-bot-level{
    position: relative;
    display: flex;
    margin-bottom: 2%;
}

.new-admin-form{
    position: relative;
    /* right: 0px; */
    border: 1px solid grey;
    margin-left: 25%;
    width: 500px;
    height: 100px;
}

.new-artist-form{
    position: relative;
    border: 1px solid grey;
}

.fs-image{
    position: relative;
    width: 60%;
    height: 80%;
    margin-left: 20%;
    margin-top: 5%;
}

.admin-input-fields{
    margin-left: 1%;
}

.submit-button{
    border: 3px solid grey;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 2%;
    font-size: 2em;
}

.submit-button:hover{
    font-weight: 500;
    background-color: black;
    color: white;
}

@media(max-width: 400px){
    .full-screen{
        border: 1px solid grey;
        z-index: 5;
        position: absolute;
        left: 0;
        top: 50px;
        height: 200vh;
        width: 98vw;
        background-color: rgba(255, 255, 255, .95);
        color: black;
        text-align: left;
        font-weight: 200;
    }

    .fs-top-title{
        border: 1px solid grey;
        width: 34%;
        padding-left: .5%;
        /* font-weight: 100; */
        font-size: 1em;
        overflow: scroll;
        /* margin-left: .5%; */
    }

    .fs-top-level{
        position: relative;
        display: block;
        margin-bottom: 1%;
    }
    .fs-bot-level{
        position: relative;
        display: block;
        margin-bottom: 2%;
    }

    .fs-top{
        display: flex;
        height: 250px;
    }

    .project-image{
        border: 1px solid grey;
        height: 300px;
        width: 95%;
        margin-left: 1%;
        /* font-weight: 500; */
        font-size: 1.5em;
    
    }

    .fs-bottom-mock-loc-img{
        display: block;
        /* height: 100vh; */
        /* padding-left: 1%; */
    }
    .admin-option{
        border: 2px solid grey;
        margin-left: 1%;
        height: 375px;
        width: 100vw;
    }

    .window-open{
        background-color: green;
        height: 100px;
        width: 300px;
        margin-top: auto;
    }
    
    .window-closed{
        background-color: red;
        height: 100px;
        width: 300px;
        margin-top: auto;
    }

    .open-close-option{
        border: 1px solid grey;
        height: 100px;
        width: 300px;
        margin: auto;
        /* padding: 1%; */
        font-size: 2em;
        font-weight: 500;
        text-align: center;
    }

    
}
