.sign-in-div{
    /* border: 1px solid #333; */
    margin: auto;
    height: 350px;
    width: 40%;
}

.sign-in-form{
    margin-top: 20px;
}

.sign-in-mess{
    font-weight: 100;
    text-align: left;
    /* margin-left: 392px; */
}

.sign-in-button{
    /* background: #333; */
    background: rgb(218,55,49);
    margin-top: 10px;
    color: white;
    
    
    
    border-style: outset;
    
    border-color:black;
    
    height: 100%;
    
    width: 40%;
    
    font-family:Arial, Helvetica, sans-serif;
    font-size: 2em;
    letter-spacing: .2ch;
    font-weight: 500;   
}

.sign-in-button:hover{
    border: 1px solid white;
}

.recovery-creation-links{
    font-weight: 100;
    font-size: 1em;
}

.signing-you-in{
    font-size: 1.6em;
    font-weight: 300;
}

@media(max-width: 400px){
    .sign-in-div{
        border: none;
        margin: auto;
        height: 400px;
        width: 90%;
        
    }

    .sign-in-mess{
        font-weight: 100;
        text-align: left;
        margin-left: 20px;
    }

    .sign-in-button{
        /* background: #333; */
        background: rgb(218,55,49);
        margin-top: 10px;
        color: white;
        
        
        
        border-style: outset;
        
        border-color:black;
        
        height: 100%;
        
        width: 90%;
        
        font-family:Arial, Helvetica, sans-serif;
        font-size: 2em;
        letter-spacing: .2ch;
        font-weight: 500;   
    }

    input.input-field, input.password{
        width: 300px;
        margin-left: 5px;
    }


}