.tattreq-attrs{

    border: 1px solid white;
    width: 450px;
    padding: 15px;
    text-align: left;
    margin-bottom: 1%;
    margin: auto;
}
.saved-bu{
  
    border: 1px solid white;
    width: 450px;
    padding: 15px;
    text-align: left;
    margin-bottom: 1%;
    background-color: grey;
    margin: auto;
}



.tattoo-request-panel{
    /* display: flex; */
    /* width: 30%; */
    margin: auto;
}

#admin-tr-h1{
  /* text-align: left; */
  font-weight: 300;
  margin-top: 30px;
}

#tattreq-details{
    /* border: 1px solid white; */
    /* margin-left: 10%; */
    position: relative;
    /* left: 250; */
    /* width: 200%; */
    /* top: 250px; */
    height: 250%;
    /* background-color: white;
    color: black; */
    /* float: right; */
}

 button#approve{
    background-color:  rgba(69, 218, 49, .8) ;
    color: white;
 }

 button#decline{
    background-color:  rgba(218,55,49, .8) ;
    color: white;
 }

 .decision-button{
  width: 100%;
  height: 50px;
  margin: .5%;
  border-radius: 5%;
  font-size: 1.5em;
  /* font-weight: 100; */
  /* padding: 1; */
  /* background-color: black; */
}

 /* TOGGLE BUTTON */
 .wrg-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .wrg-toggle-check, .wrg-toggle-uncheck {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 0;
    transition: opacity .25s ease;
  }
  .wrg-toggle-check {
    left: 8px;
  }
  .wrg-toggle-uncheck {
    opacity: 1;
    right: 10px;
  }
  
  .wrg-toggle-uncheck span, 
  .wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
  }
  
  .wrg-toggle-container{
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    transition: all .2s ease;
  }
  
  .wrg-toggle-circle{
    transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;
    box-sizing: border-box;
    transition: all .25s ease;
  }

  .wrg-toggle--checked .wrg-toggle-check{
    opacity: 1;
  }
  .wrg-toggle--checked .wrg-toggle-uncheck {
    opacity: 0;
  }
  .wrg-toggle--checked .wrg-toggle-circle {
    left: 27px;
  }

  #toggle-box{
      position: relative;
      /* text-align: left; */
      margin: auto;
  }

 /* END TOGGLE BUTTON */

@media(max-width: 380px){
  .tattreq-attrs{
      border: 1px solid white;
      width: 300px;
      padding: 15px;
      text-align: left;
      margin-bottom: 1%;
      margin-left: 20px;
  }
  .saved-bu{
    border: 1px solid white;
    width: 300px;
    padding: 15px;
    text-align: left;
    margin-bottom: 1%;
    background-color: grey;
    margin-left: 20px;
  }

  .decision-button{
    width: 100%;
    height: 50px;
    margin: .5%;
    border-radius: 5%;
    /* padding: 1; */
    /* background-color: black; */
  }
}





