.to-be-scheduled{
    border: 1px solid white;
    /* text-align: left; */
    height: 300px;
    display: flex;
    overflow: scroll;
    
}

.title-description{
    text-align: left;
    font-size: 2em;
}

.project{
    border: 1px solid white;
    height: 70px;
    width: 300px;
    float: left;
    padding: 2px;
    margin-left: 20px;
    margin-bottom: 5px;
    text-align: left;
    overflow: scroll;
    /* position: relative; */
}

.forward-icon{
    color: white;
    height: 2ppx;
    width: 20px;
    background-color: white;
    float: right;
    position: relative;
    margin-right: 10px;
}   

.id-icon{
    display: flex;
}

#toggle-button{
    position: relative;
    left: 0px;
}

.a-and-s-button:hover{
    background-color: white;
    color: black;
}   

@media(max-width: 400px){
    .to-be-scheduled{
        border: 1px solid white;
        /* text-align: left; */
        height: 200px;
        display: block;
        overflow: scroll;
        
    }

    .project{
        border: 1px solid white;
        height: 70px;
        width: 95vw;
        float: left;
        padding: 2px;
        margin-left: 1px;
        /* margin-bottom: 5px; */
        text-align: left;
        overflow: scroll;
        /* position: relative; */
    }
}