.user-layout{
    display: flex;
}

.user-title{
    margin-left: 10px;
    text-align: left;
}


.user-mod{
    /* position: relative; */
    border: 1px solid white;
    width: 50%;
    margin-left: 10px;
    margin-bottom: 15px;
    height: 600px;  
    overflow: scroll;
    text-align: left;

}

.user-request-data{
    border: 1px solid white;
    width: 75%;
    margin-left: 10px;
    margin-bottom: 5px;
}

.id-preview{
    margin-left: 5px;
    width: 70%;
    height: 300px;
}

.image-preview{
    
    width: 60%;
    height: 200px;
}

@media(max-width: 400px){
    .user-layout{
        display: block;
    }

    .user-mod{
        width: 90%;
    }

    .id-preview{
        width: 90%;
        /* height: 300px; */
    }

    
}
